import styled from "@emotion/styled";
import { useState } from "react";
import Typography from "../../component/Typography";
import { ButtonProps } from "./props";
import { Colors } from "../Color";

const Container = styled.div<{ border?: boolean; circle?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  max-width: 170px;
  cursor: pointer;

  border-radius: ${({ circle }) => (circle ? "50%" : "10px")};
  border: ${({ border }) => (border ? "1px solid #262626" : "none")};

  :hover {
    background-color: ${Colors.gray90};
  }
`;

export default function SmallButton(props: ButtonProps) {
  const [hover, setHover] = useState(false);

  const onClick = () => {
    if (!props.disabled) {
      props.onClick && props.onClick();
    }
  };
  const icon =
    props.disabled && props.disabledIcon
      ? props.disabledIcon
      : props.selected && props.selectedIcon
      ? props.selectedIcon
      : hover && props.hoverIcon
      ? props.hoverIcon
      : props.icon;

  const setHoverIcon = (e: any) => {
    setHover(true);
  };
  const setNormalIcon = (e: any) => {
    setHover(false);
  };

  return (
    <Container onClick={onClick} border={props.border} circle={props.circle} style={props.style}>
      {icon && <img src={icon} alt="icon" onMouseOver={setHoverIcon} onMouseOut={setNormalIcon} />}
      {props.text && <Typography variant="4/medium" text={props.text} color="white" />}
    </Container>
  );
}
