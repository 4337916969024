import styled from "@emotion/styled";
import IcClosePopup from "../../component/icon/ic_close_popup.svg";
import IcLogo from "../../component/icon/logo.svg";
import ImgQRCode from "../../component/qr/QRCode.png";

const Container = styled.div`
  width: 429px;
  height: 451px;
  padding: 20px;
  background: white;
  border-radius: 30px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const Header = styled.div`
  align-self: stretch;
  background: white;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
`;

const Title = styled.div`
  flex: 1 1 0;
  color: black;
  font-size: 24px;
  font-family: Pretendard;
  font-weight: 700;
  line-height: 33.6px;
  word-wrap: break-word;
`;

const IconContainer = styled.div`
  width: 44px;
  height: 44px;
  background: #f4f4f4;
  border-radius: 38px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  display: flex;
  cursor: pointer;
`;

const Image = styled.img`
  width: 18.15px;
  height: 18.15px;
`;

const Content = styled.div`
  height: 407px;
  padding-bottom: 20px;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
`;

const QRSection = styled.div`
  align-self: stretch;
  height: 264px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 36px;
  display: flex;
`;

const QRCodeContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #b2b2b2;
  border-radius: 20px;
`;

const QRText = styled.div`
  align-self: stretch;
  text-align: center;
  color: black;
  font-size: 28px;
  font-family: Pretendard;
  font-weight: 700;
  line-height: 39.2px;
  word-wrap: break-word;
`;

const Divider = styled.div`
  align-self: stretch;
  padding: 0 40px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: inline-flex;
`;

const Line = styled.div`
  flex: 1 1 0;
  height: 1px;
  background: #d9d9d9;
`;

const OrText = styled.div`
  text-align: center;
  color: #8d8d8d;
  font-size: 15px;
  font-family: Pretendard;
  font-weight: 400;
  line-height: 21px;
  word-wrap: break-word;
`;

const AppSection = styled.div`
  justify-content: flex-start;
  align-items: center;
  gap: 17px;
  display: inline-flex;
`;

const AppIconContainer = styled.div`
  width: 70px;
  height: 70px;
  background: black;
  border-radius: 16px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;

  > img {
    width: 52.64px;
    height: 52.64px;
  }
`;

const AppText = styled.div`
  color: black;
  font-size: 17px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 23.8px;
  word-wrap: break-word;
`;

interface Props {
  onClose: () => void;
}
export default function DownloadPopup(props: Props) {
  return (
    <Container>
      <Header>
        <Title> </Title>
        <IconContainer onClick={props.onClose}>
          <Image src={IcClosePopup} />
        </IconContainer>
      </Header>
      <Content>
        <QRSection>
          <QRCodeContainer>
            <img src={ImgQRCode} alt="qr" width={150} />
          </QRCodeContainer>
          <QRText>
            스마트폰으로 카메라 앱을 켜서
            <br />
            QR 코드를 포커스해보세요.
          </QRText>
        </QRSection>
        <Divider>
          <Line />
          <OrText>or</OrText>
          <Line />
        </Divider>
        <AppSection>
          <AppIconContainer>
            <img src={IcLogo} alt="logo" width={70} />
          </AppIconContainer>
          <AppText>
            앱스토어, 구글플레이에 방문하여
            <br />
            '스톡나우'를 검색해보세요.
          </AppText>
        </AppSection>
      </Content>
    </Container>
  );
}
