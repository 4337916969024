import styled from "@emotion/styled";
import { Colors } from "../Color";
import Typography from "../Typography";
import { useState } from "react";

const IconElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.gray90};
  border-radius: 50%;

  width: 40px;
  height: 40px;
  overflow: hidden;

  border: 1px solid #64646466;

  > img {
    width: 100%;
    height: 100%;
  }
`;

interface Props {
  iconUrl?: string;
  name: string;
  style?: React.CSSProperties;
  size?: "small" | "large";
}

export default function TickerIcon(props: Props) {
  const [isImageError, setIsImageError] = useState(false);
  const textVariant = props.size === "large" ? "11/bold" : "1/bold";
  return (
    <IconElement style={props.style}>
      {isImageError ? (
        <Typography variant={textVariant} color={Colors.white}>
          {props.name}
        </Typography>
      ) : (
        <img src={props.iconUrl} alt={"ticker-icon"} onError={(e) => setIsImageError(true)} />
      )}
    </IconElement>
  );
}
