import { useQuery } from "@tanstack/react-query";
import {
  getAllRealtimeEarningsCallSummarySimulationState,
  getRealtimeEarningsCallSummarySimulationResult,
  SimulationState,
} from "../../api/earningscall";
import styled from "@emotion/styled";
import { Row } from "../../component/row";
import Typography from "../../component/Typography";
import { useEffect, useState } from "react";
import { Colors } from "../../component/Color";
import SimulationResultItem from "./SimulationResultItem";
import SmallButton from "../../component/buttons/SmallButton";

const Container = styled.div`
  user-select: text;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 100px 20px 20px 20px;
`;

const Headers = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${Colors.black};
  padding: 20px;
  z-index: 100;
  border-bottom: 1px solid ${Colors.gray50};
`;

const Label = styled.div`
  font-size: 14px;
`;

const Select = styled.select`
  width: 400px;
  height: 36px;
  border-radius: 8px;
`;

const Divider = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: ${Colors.gray50};
`;
const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Checkbox = styled.input`
  width: 30px;
  height: 30px;
`;

const ScriptContainer = styled.div`
  position: fixed;
  right: 0;
  padding: 5px;
  top: 100px;
  width: 500px;
  height: 100vh;
  background-color: ${Colors.black};
  border-left: 1px solid ${Colors.gray50};
  overflow-x: scroll;
`;

interface Script {
  s?: number;
  e?: number;
  tr?: string;
}

export default function RealtimeEarningsCallSummary() {
  const [selectedSimulationRequestId, setSelectedSimulationRequestId] = useState("");
  const [showPreviousDiff, setShowPreviousDiff] = useState(false);
  const [showScript, setShowScript] = useState(false);
  const [script, setScript] = useState<Script[]>([]);
  const [selectedSimulationState, setSelectedSimulationState] = useState<SimulationState | undefined>(undefined);
  const { data: simulationStateList } = useQuery({
    queryKey: ["realtime-earnings-call-summary-simulation-state"],
    queryFn: () => getAllRealtimeEarningsCallSummarySimulationState(),
  });

  const { data: simulationResultList, refetch: refetchSimulationResultList } = useQuery({
    queryKey: ["realtime-earnings-call-summary-simulation-result", selectedSimulationRequestId],
    queryFn: () => getRealtimeEarningsCallSummarySimulationResult(selectedSimulationRequestId),
  });

  useEffect(() => {
    setSelectedSimulationState(simulationStateList?.find((state) => state.requestId === selectedSimulationRequestId));
  }, [simulationStateList, selectedSimulationRequestId]);

  const stateTitle = selectedSimulationState ? `${selectedSimulationState.ticker} ${selectedSimulationState.eventTitle}` : "";

  useEffect(() => {
    if (selectedSimulationState?.transcriptUrl) {
      console.log("fetch");
      fetch(selectedSimulationState.transcriptUrl)
        .then((res) => res.text())
        .then((text) => {
          setScript(text.split("\n").map((line) => JSON.parse(line) as Script));
        });
    } else {
      setScript([]);
    }
  }, [selectedSimulationState]);
  return (
    <Container>
      <Headers>
        <Row style={{ gap: 10 }}>
          <Typography variant="12/bold">Select Simulation</Typography>
          <Select value={selectedSimulationRequestId} onChange={(e) => setSelectedSimulationRequestId(e.target.value)}>
            <option value="">Select Simulation</option>
            {simulationStateList?.map((state) => (
              <option key={state.requestId} value={state.requestId}>
                {state.ticker} {state.eventTitle} / {state.summarizeMethod} / {state.intervalSec}s / {new Date(state.dateCreated).toLocaleString()} /{" "}
                {state.status}
              </option>
            ))}
          </Select>
          <Row>
            <Checkbox type="checkbox" checked={showPreviousDiff} onChange={() => setShowPreviousDiff(!showPreviousDiff)} />
            <Typography variant="8/regular">이전 요약 비교</Typography>
          </Row>
          <Row>
            <Checkbox type="checkbox" checked={showScript} onChange={() => setShowScript(!showScript)} />
            <Typography variant="8/regular">스크립트 보기</Typography>
          </Row>
        </Row>
      </Headers>
      <Row style={{ gap: 20, alignItems: "center" }}>
        <Typography variant="12/bold">Simulation Result - {stateTitle}</Typography>
        <SmallButton text="Reload" onClick={() => refetchSimulationResultList()} />
      </Row>
      <Typography variant="4/regular">
        {selectedSimulationState?.requestId} / {selectedSimulationState?.summarizeMethod} / {selectedSimulationState?.intervalSec}s /{" "}
        {selectedSimulationState?.dateCreated} / {selectedSimulationState?.status} / count : {simulationResultList?.length}
        <pre>{selectedSimulationState?.prompt}</pre>
      </Typography>
      <ResultContainer>
        {simulationResultList?.map((result, index) => (
          <SimulationResultItem
            onSentenceClick={(startTimestamp) => {
              const element = document.getElementById(String(startTimestamp));
              if (element) {
                element.scrollIntoView({ behavior: "smooth" });
              }
            }}
            key={`${result.requestId}-${result.fromTimestamp}-${result.toTimestamp}`}
            result={result}
            prevResult={index > 0 ? simulationResultList[index - 1] : undefined}
            showPreviousDiff={showPreviousDiff}
          />
        ))}
      </ResultContainer>
      <SmallButton text="Reload" onClick={() => refetchSimulationResultList()} />
      {showScript && (
        <ScriptContainer>
          <Typography variant="12/bold">Script</Typography>
          {script.map((s) => (
            <Row style={{ gap: 20, alignItems: "flex-start" }} key={`${s.s}-${s.e}`} id={`${s.s}`}>
              <Typography variant="2/regular" textAlign="right">
                {String(Math.floor(Number(s.s) / 60)).padStart(2, "0")}:{String(Math.floor(Number(s.s) % 60)).padStart(2, "0")}
              </Typography>
              <Typography variant="6/semibold">{s.tr}</Typography>
            </Row>
          ))}
        </ScriptContainer>
      )}
    </Container>
  );
}
