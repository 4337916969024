import styled from "@emotion/styled";
import { Colors } from "../../component/Color";

import TickerItemPlayIcon from "../../component/ticker-item/images/play_selected.svg";
import Typography from "../../component/Typography";
import ImgQrCode from "../../component/qr/QRCode.png";
import ImgDefaultPageMain from "./images/default_page_main.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DummyIcon = styled.div`
  width: 396px;
  height: 150px;
  margin-bottom: 20px;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const BottomDialog = styled.div`
  position: absolute;
  bottom: 0px;

  padding: 20px;
  border-radius: 20px;
  opacity: 0px;

  background-color: ${Colors.gray100};

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 20px;
`;

const QrSection = styled.div`
  width: 87px;
  height: 87px;
  border-radius: 20px;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const BottomDialogText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
`;

export default function ChooseEarnings() {
  return (
    <Container>
      <DummyIcon>
        <img src={ImgDefaultPageMain} alt="play" />
      </DummyIcon>

      <Typography variant="10/medium" color={Colors.gray40} textAlign="center">
        캘린더에서 어닝콜을 선택하거나
        <br />
        종목을 검색해주세요.
      </Typography>

      <BottomDialog>
        <QrSection>
          <img src={ImgQrCode} alt="qr" />
        </QrSection>
        <BottomDialogText>
          <Typography variant="8/medium" color={Colors.gray40} textAlign="left" component="div">
            스톡나우 앱에서 더 편리하게
          </Typography>
          <Typography variant="8/regular" color={Colors.gray60} textAlign="left" component="div">
            앱에서 어닝콜과 실적 발표를 실시간으로 <br />
            더욱 편리하게 확인하세요.
          </Typography>
        </BottomDialogText>
      </BottomDialog>
    </Container>
  );
}
