import Typography from "../../component/Typography";
import { Colors } from "../../component/Color";
import styled from "@emotion/styled";
import { EarningsDetailResponse } from "../../api";
import { getPeriodText } from "../../util/earnings";
import { DateTimeUtils } from "../../util/time";
import TickerIcon from "../../component/icon/TickerIcon";

import IconWarning from "../../component/icon/warnings.svg";
import IconLive from "../../component/icon/live.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const IconWarningContainer = styled.div<{ isLive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ isLive }) => (isLive ? Colors.gray20 : Colors.gray90)};
  border-radius: 50%;
  width: 91px;
  height: 91px;
  margin-bottom: 20px;
`;

const SubTextContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const UpcomingContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 460px;
  padding: 60px 20px 40px 20px;
  border-radius: 40px;
  opacity: 0px;

  background-color: #191919;

  #icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    top: -50px;
    border: 1px solid #64646466;
    background-color: ${Colors.gray60};
  }
`;

const PeriodContainer = styled.div`
  margin-top: 24px;
`;

const FeaturesContainer = styled.div`
  margin-top: 26px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Separator = styled.div`
  width: 1px;
  height: 10px;
  background-color: #353535;
`;

const HSeparator = styled.div`
  margin-top: 40px;

  width: 100%;
  height: 1px;
  background-color: #252525;
`;

const DateTimeContainer = styled.div`
  margin-top: 30px;
`;

interface NoTranscriptProps {
  isPast: boolean;
  isLive: boolean;
  earnings: EarningsDetailResponse;
}
export default function NoTranscript({ isPast, isLive, earnings }: NoTranscriptProps) {
  const textAndIcon = isPast
    ? isLive
      ? {
          text: (
            <>
              진행자가 라이브를 시작했습니다.
              <br />
              잠시 뒤 어닝콜이 시작됩니다.
            </>
          ),
          icon: IconLive,
        }
      : {
          text: (
            <>
              어닝콜 라이브가 예정된 시간에 <br />
              시작되지 않았습니다.
            </>
          ),
          subText: (
            <>
              10분 이 내에 시작되지 않으면 아래와 같은 상황일 수 있습니다. <br />
              <br />• 어닝콜 라이브가 취소된 경우 <br />• 오디오 또는 스크립트 파일을 확인할 수 없는 경우
            </>
          ),
          icon: IconWarning,
        }
    : undefined;
  return isPast ? (
    <Container>
      <IconWarningContainer isLive={isLive}>
        <img src={textAndIcon?.icon} alt="warning" />
      </IconWarningContainer>
      <Typography variant="8/medium" textAlign="center" color={Colors.gray40}>
        {textAndIcon?.text}
      </Typography>
      {textAndIcon?.subText && (
        <SubTextContainer>
          <Typography variant="7.5/regular" textAlign="center" color={Colors.gray60}>
            {textAndIcon.subText}
          </Typography>
        </SubTextContainer>
      )}
    </Container>
  ) : (
    <UpcomingContainer>
      <TickerIcon
        size="large"
        iconUrl={earnings.tickerIconUrl}
        name={earnings.ticker}
        style={{ position: "absolute", top: "-50px", width: 100, height: 100 }}
      />
      <Typography variant="12/bold" textAlign="center" color={Colors.white}>
        {earnings.ticker}
      </Typography>
      <Typography variant="5/regular" textAlign="center" color={Colors.gray50}>
        {earnings.companyName}
      </Typography>
      <PeriodContainer>
        <Typography variant="12/bold" textAlign="center" color={Colors.white}>
          {getPeriodText(earnings.periodYear, earnings.period)} 어닝콜
        </Typography>
      </PeriodContainer>
      <FeaturesContainer>
        <Typography variant="5/regular" textAlign="center" color={Colors.gray50}>
          라이브
        </Typography>
        <Separator />
        <Typography variant="5/regular" textAlign="center" color={Colors.gray50}>
          실시간 Ai 변역
        </Typography>
        <Separator />
        <Typography variant="5/regular" textAlign="center" color={Colors.gray50}>
          Ai 요약
        </Typography>
      </FeaturesContainer>
      <HSeparator />
      <DateTimeContainer>
        <Typography variant="8/semibold" textAlign="center" color={Colors.gray20}>
          {DateTimeUtils.formatDateTimeMinExceptYear(earnings.earningsConferenceCalls[0].eventAt)} 시작
        </Typography>
      </DateTimeContainer>
    </UpcomingContainer>
  );
}
