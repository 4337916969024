// create react component
import styled from "@emotion/styled";
import { Ticker } from "../../api";
import TopHeader, { Tab } from "../../component/top-header";
import Feature from "./Feature";
import IcAppStore from "./images/appstore.svg";
import LogoWithText from "./images/logo_with_text.svg";
import IcPlayStore from "./images/playstore.svg";
import LargeButton from "../../component/buttons/LargeButton";

import ImgEarningsCall from "./images/earnings_call.png";

import ImgBranding1 from "./images/branding_image_1.png";
import ImgBrandingScriptElon from "./images/script_elon.png";
import ImgBrandingScriptJensen from "./images/script_jensen.png";
import ImgBrandingScriptNadella from "./images/script_nadella.png";
import ImgBrandingScriptSundar from "./images/script_sundar.png";
import ImgBrandingScriptMark from "./images/script_mark.png";

import ImgFeatureEarnings from "./images/feature_earnings_detail.png";
import ImgFeatureEarningsCall from "./images/feature_earnings_call.png";
import ImgFeatureWatchlist from "./images/feature_watchlist.png";

import CompanyInfo from "./CompanyInfo";
import ImgStocknowLogo from "./images/stocknow_logo.svg";

import { AppStoreUtil } from "../../util/appstore";
import { useIsMobile } from "../../util/mediaquery";
import MobileHome from "./MobileHome";
import Typography from "../../component/Typography";

interface Props {
  ticker?: Ticker;
}

const Container = styled.div`
  padding: 0px 0 50 0px;
`;

const BrandingMessage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 120px;

  > img {
    width: 187px;
    height: 38px;
  }
`;

const MainTextContainer = styled.div`
  margin-top: 40px;
  text-align: center;
`;

const AppDownloadContainer = styled.div`
  display: flex;
  margin-top: 40px;

  div + div {
    margin-left: 20px;
  }
  > div {
    width: 170px;
  }
`;

const Images = styled.div`
  position: relative;
  margin-top: 100px;
  width: 100%;
  height: 730px;
  text-align: center;
  overflow: hidden;

  > img {
    position: absolute;
  }
`;

const Bg = styled.div`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.09) 0%, #000000 100%);

  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 185px;
`;

const BottomBrandingMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
  padding-bottom: 200px;
`;

const BottomBrandingMessageIcon = styled.div`
  border: 1px solid #262626;

  padding: 19.6px 18.05px 19.6px 21.14px;
  border-radius: 32px;
`;

const BottomBrandingMessageText = styled.div`
  font-size: 70px;
  text-align: center;
  margin-top: 40px;
`;

export default function Home(props: Props) {
  const isMobile = useIsMobile();
  if (isMobile) {
    return <MobileHome />;
  }

  return (
    <Container>
      <TopHeader selectedTab={Tab.Intro} />
      <BrandingMessage>
        <div>
          <img src={LogoWithText} alt="" />
        </div>
        <MainTextContainer>
          <Typography variant="20/bold" textAlign="center">
            미국 주식 어닝콜을 <br />
            실시간 번역과 라이브로.
          </Typography>
        </MainTextContainer>
        <AppDownloadContainer>
          <LargeButton selected={true} icon={IcPlayStore} text="GooglePlay" onClick={() => window.open(AppStoreUtil.playStoreUrl, "_blank")}>
            시작하기
          </LargeButton>
          <LargeButton selected={true} icon={IcAppStore} text="AppStore" onClick={() => window.open(AppStoreUtil.appStoreUrl, "_blank")}>
            시작하기
          </LargeButton>
        </AppDownloadContainer>

        <Images>
          <img src={ImgBrandingScriptNadella} width={446} alt="" style={{ left: "50%", transform: "translate(560px, 265px)" }} />
          <img src={ImgBrandingScriptSundar} width={446} alt="" style={{ left: "50%", transform: "translate(-900px, 442px)" }} />

          <img src={ImgBrandingScriptJensen} width={446} alt="" style={{ left: "50%", transform: "translate(290px, 138px)" }} />
          <img src={ImgBrandingScriptElon} width={446} alt="" style={{ left: "50%", transform: "translate(-630px, 126px)" }} />
          <img src={ImgBranding1} width={490} alt="branding_1" style={{ left: "50%", transform: "translate(-50%)" }} />

          <img src={ImgBrandingScriptMark} width={446} alt="" style={{ left: "50%", transform: "translate(100px, 510px)" }} />
        </Images>
        <Bg />
      </BrandingMessage>

      <Feature featureName="실시간 번역 & Ai 요약" image={ImgEarningsCall} imageWidth={845} imageAlign={"bottom"}>
        실시간 번역과 Ai 요약으로 <br />
        가장 빠르게 투자 인사이트를 <br /> 얻으세요.
      </Feature>

      <Feature featureName="실적 캘린더" image={ImgFeatureEarningsCall} imageWidth={490} imageAlign={"left"}>
        글로벌 기업의 실적 발<br />
        표, 한국 시간으로 쉽게 <br />
        체크하세요.
      </Feature>

      <Feature featureName="실적 데이터" image={ImgFeatureEarnings} imageWidth={490} imageAlign={"right"}>
        최근 실적을 쉽게 비교 <br />
        하고 다가올 발표 일정 <br />
        놓치지 마세요.
      </Feature>

      <Feature featureName="실적 발표 리마인더" image={ImgFeatureWatchlist} imageWidth={490} imageAlign={"left"}>
        관심 종목의 실적 발표
        <br /> 리마인더로 정보 선점 <br />
        기회를 잡으세요.
      </Feature>

      <BottomBrandingMessage>
        <BottomBrandingMessageIcon>
          <img src={ImgStocknowLogo} alt="App Icon" />
        </BottomBrandingMessageIcon>
        <BottomBrandingMessageText>
          <Typography variant="19/bold" textAlign="center">
            실시간 미국 주식 정보는 <br />
            스톡나우에서.
          </Typography>
        </BottomBrandingMessageText>

        <AppDownloadContainer style={{ marginTop: "50px" }}>
          <LargeButton selected icon={IcPlayStore} text="GooglePlay" onClick={() => window.open(AppStoreUtil.playStoreUrl, "_blank")}>
            시작하기
          </LargeButton>
          <LargeButton selected icon={IcAppStore} text="AppStore" onClick={() => window.open(AppStoreUtil.appStoreUrl, "_blank")}>
            시작하기
          </LargeButton>
        </AppDownloadContainer>
      </BottomBrandingMessage>

      <CompanyInfo />
    </Container>
  );
}
