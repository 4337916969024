import styled from "@emotion/styled";
import { Colors } from "../../component/Color";
import Typography, { TextVariants } from "../../component/Typography";
import { MOBILE_BREAKPOINT } from "../../util/mediaquery";

const Container = styled.div`
  margin-top: 200px;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  padding-bottom: 200px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 70px;
    padding-bottom: 70px;
  }
`;

const HContainer = styled.div`
  margin-top: 100px;

  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: center;
  padding-bottom: 100px;
`;

const FeatureName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 48px;
  padding: 0px; 20px;
  border-radius: 80px;

  padding: 0 20px;
  background-color: ${Colors.gray90};
`;

const FeatureContainer = styled.div``;

const FeatureDescription = styled.div`
  margin-top: 24px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    max-width: 550px;
  }

  word-break: keep-all;
  overflow-wrap: break-word;
`;

const ImageContainer = styled.div`
  margin-top: 100px;

  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 50px;
  }
`;

interface Props {
  featureName?: string;
  text?: string;
  image?: string;
  imageWidth?: number;
  children?: React.ReactNode;
  imageAlign: "left" | "right" | "bottom";
  textVariant?: TextVariants;
}

export default function Feature(props: Props) {
  const textAlign = props.imageAlign === "left" || props.imageAlign === "right" ? "left" : "center";
  const margin = props.imageAlign === "right" ? "0 150px 0 0" : props.imageAlign === "left" ? "0 0 0 150px" : "0";
  const textVariant = props.textVariant ? props.textVariant : "19/bold";
  if (props.imageAlign === "bottom") {
    return (
      <Container>
        <FeatureName>
          <Typography variant="9/semibold" text={props.featureName} color={Colors.gray30} />
        </FeatureName>
        <FeatureDescription>
          <Typography variant={textVariant} textAlign={textAlign} component="div">
            {props.children}
          </Typography>
        </FeatureDescription>
        <ImageContainer>
          <img src={props.image} alt="earnings-call" width={props.imageWidth} />
        </ImageContainer>
      </Container>
    );
  } else {
    return (
      <HContainer>
        {props.imageAlign === "left" && (
          <ImageContainer>
            <img src={props.image} alt="earnings-call" width={props.imageWidth} />
          </ImageContainer>
        )}
        <FeatureContainer style={{ margin: margin }}>
          <div style={{ display: "flex" }}>
            <FeatureName>
              <Typography variant="9/semibold" text={props.featureName} color={Colors.gray30} />
            </FeatureName>
            <div style={{ flex: 1, height: 24 }} />
          </div>
          <FeatureDescription>
            <Typography variant={textVariant} textAlign={textAlign} component="div">
              {props.children}
            </Typography>
          </FeatureDescription>
        </FeatureContainer>

        {props.imageAlign === "right" && (
          <ImageContainer>
            <img src={props.image} alt="earnings-call" width={props.imageWidth} />
          </ImageContainer>
        )}
      </HContainer>
    );
  }
}
