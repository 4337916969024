// create react component
import styled from "@emotion/styled";

import TopHeader, { Tab } from "../../component/top-header";
import { AppStoreUtil } from "../../util/appstore";
import EarningsCallCalendar from "../earnings-call-calendar";
import EarningsCallTranscriptPlayer from "./EarningsCallTranscriptPlayer";

interface Props {
  earningsId?: number;
}

const Container = styled.div`
  overflow: hidden;
`;

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 86px;
  position: relative;
`;

export default function EarningsCall(props: Props) {
  if (AppStoreUtil.isMobile) {
    window.location.href = "/";
    return;
  }
  return (
    <Container>
      <TopHeader selectedTab={Tab.EarningsCall} showBorder />
      <Layout>
        <EarningsCallCalendar />
        <EarningsCallTranscriptPlayer />
      </Layout>
    </Container>
  );
}
