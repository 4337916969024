import styled from "@emotion/styled";
import { Colors } from "../Color";
import Typography from "../Typography";
import { ButtonProps } from "./props";
import { useState } from "react";

const Container = styled.div<{ selected: boolean; border?: boolean; circle?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 42px;
  height: 42px;
  cursor: pointer;

  border-radius: ${({ circle }) => (circle ? "50%" : "78px")};
  background-color: ${({ selected }) => (selected ? Colors.gray10 : Colors.black)};
  border: ${({ border }) => (border ? `1px solid ${Colors.gray90}` : "none")};
  padding: ${({ circle }) => (circle ? "0" : "0 14px 0px 12px")};

  > img + span {
    margin-left: 6px;
  }

  ${({ selected }) =>
    !selected &&
    `
    :hover {
      background-color: ${Colors.gray90};
    }
  `}
`;

export default function MediumButton(props: ButtonProps) {
  const [hover, setHover] = useState(false);

  const onClick = () => {
    if (!props.disabled) {
      props.onClick && props.onClick();
    }
  };

  const icon =
    props.disabled && props.disabledIcon
      ? props.disabledIcon
      : props.selected && props.selectedIcon
      ? props.selectedIcon
      : hover && props.hoverIcon
      ? props.hoverIcon
      : props.icon;

  const setHoverIcon = (e: any) => {
    setHover(true);
  };
  const setNormalIcon = (e: any) => {
    setHover(false);
  };
  return (
    <Container onClick={onClick} border={props.border} circle={props.circle} style={props.style} selected={props.selected || false}>
      {icon && <img src={icon} alt="icon" onMouseOver={setHoverIcon} onMouseOut={setNormalIcon} />}
      {props.text && <Typography variant="7/medium" text={props.text} color={props.selected ? Colors.black : Colors.white} />}
    </Container>
  );
}
