import { DateTime } from "luxon";
import { callApi } from "./common";
import { LastTrade, MarketOpenTime, MarketStatus, Ticker, TickerStatsResponse } from "./model";
import { TimeSpan } from "./quries/ticker";

export function searchTicker(query: string): Promise<Ticker[]> {
  return callApi("search", { query });
}

export function getTicker(ticker: string): Promise<Ticker> {
  return callApi(`tickers/${ticker}`);
}

export function getTickerStats(ticker: string, timespan: TimeSpan): Promise<TickerStatsResponse> {
  return callApi(`tickers/${ticker}/stats`, { timespan });
}

export function getTodayTickerStats(ticker: string): Promise<TickerStatsResponse> {
  return callApi(`tickers/${ticker}/today-stats`);
}

export function getTickerRecentDailyStats(ticker: string): Promise<TickerStatsResponse> {
  return callApi(`tickers/${ticker}/recent-daily-stats`);
}

export function getTickerLastPrice(ticker: string): Promise<LastTrade> {
  return callApi(`tickers/${ticker}/last-trade`);
}

export function getTickerLastPriceList(tickerList: string[]): Promise<LastTrade[]> {
  return callApi(`last-trades`, { tickerList });
}

function fillMarketOpenTimes(values: MarketOpenTime[]): MarketOpenTime[] {
  return values.map((t) => ({
    ...t,
    regularMarketStartDateTime: DateTime.fromISO(t.regularMarketStart),
    regularMarketEndDateTime: DateTime.fromISO(t.regularMarketEnd),
    afterHoursStartDateTime: DateTime.fromISO(t.afterHoursStart),
    afterHoursEndDateTime: DateTime.fromISO(t.afterHoursEnd),
    preMarketStartDateTime: DateTime.fromISO(t.preMarketStart),
    preMarketEndDateTime: DateTime.fromISO(t.preMarketEnd),
  }));
}

export async function getMarketStatus(): Promise<MarketStatus> {
  const resp: MarketStatus = await callApi(`market-status`);
  resp.latestMarketTimes = fillMarketOpenTimes(resp.latestMarketTimes);
  resp.nextMarketTimes = fillMarketOpenTimes(resp.nextMarketTimes);

  const index = resp.latestMarketTimes[1].regularMarketStartDateTime.diffNow().milliseconds > 0 ? 0 : 1;
  resp.chartTimeRange = {
    from: resp.latestMarketTimes[index].regularMarketStartDateTime,
    to: resp.latestMarketTimes[index].regularMarketEndDateTime,
  };
  return resp;
}
