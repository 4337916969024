import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { searchTicker, Ticker } from "../../api";
import { Colors } from "../../component/Color";
import { Row } from "../../component/row";
import { Space } from "../../component/space";
import Typography from "../../component/Typography";

import IconSearchPlaceHolder from "./images/search_placeholder.svg";
import IconClose from "./images/close.svg";

import SearchItem from "./SearchItem";
import { easeOutQuart } from "../../component/transition";

const Container = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: -2.5px;
  left: 0px;
  background-color: ${Colors.black};
  width: 100%;
  bottom: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.4s ${easeOutQuart};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
`;

const SearchBar = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  background-color: ${Colors.black};
  color: ${Colors.white};
  padding: 0px 10px;

  :focus {
    outline: none;
  }
`;

const InputWapper = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${Colors.gray100};

  padding: 0px 10px;
`;

const Loading = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
`;

const SearchResultContainer = styled.div`
  height: calc(100vh - 100px);
  padding: 0 0 40px 0;
  overflow-y: scroll;
`;

const CloseWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid ${Colors.gray90};

  :hover {
    background-color: ${Colors.gray90};
  }
`;

const EmptySearchResultContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 200px 20px;
`;

interface Props {
  onClose?: () => void;
  isOpen?: boolean;
}

export default function Search(props: Props) {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<Ticker[]>([]);

  useEffect(() => {
    let active = true;

    setLoading(true);
    (async () => {
      if (inputValue.length === 0) {
        setSearchResult([]);
        setLoading(false);
        return;
      }

      const result = await searchTicker(inputValue);

      if (active) {
        console.log(result);
        setSearchResult(result as Ticker[]);
      } else {
        console.log(`${inputValue} -> ignored`);
      }
      setLoading(false);
    })();

    return () => {
      // clean up request
      active = false;
    };
  }, [inputValue]);

  const hasSearchQuery = inputValue.length > 0;
  const noResult = !isLoading && searchResult.length === 0;
  return (
    <Container isOpen={props.isOpen || false}>
      <Header>
        <Row>
          <Typography variant="12/bold" text="어닝콜 검색" component="div" />
          <Space />
          <CloseWrapper onClick={props.onClose}>
            <img src={IconClose} alt="close" />
          </CloseWrapper>
        </Row>
        <InputWapper>
          <img src={IconSearchPlaceHolder} alt="input-placeholder" />
          <SearchBar placeholder="티커를 검색해보세요." onChange={(e) => setInputValue(e.target.value)} />
          {isLoading && <Loading id="loading">...</Loading>}
        </InputWapper>
      </Header>
      {!hasSearchQuery || noResult ? (
        <EmptySearchResultContainer>
          <Typography variant="5/regular" color={Colors.gray60} textAlign="center">
            {hasSearchQuery ? (
              <>
                검색 결과가 없습니다. <br />
                스톡나우에서는 미국 주식만 검색 가능합니다. <br />
                <br />
                (한글로 검색했다면, 영문으로 검색해보세요.)
              </>
            ) : (
              "검색어를 입력해주세요."
            )}
          </Typography>
        </EmptySearchResultContainer>
      ) : (
        <SearchResultContainer>
          {searchResult.map((r) => (
            <SearchItem key={r.ticker} tickerInfo={r} onClick={() => {}} />
          ))}
        </SearchResultContainer>
      )}
    </Container>
  );
}
