import { callApi } from "./common";

export async function getAllRealtimeEarningsCallSummarySimulationState(): Promise<SimulationState[]> {
  const r: SimulationState[] = await callApi("getAllSimulationState");
  return r.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
}

export async function getRealtimeEarningsCallSummarySimulationResult(id?: string): Promise<SimulationResult[]> {
  if (id) {
    return await callApi(`getSimulationResult/${id}`);
  } else {
    return [];
  }
}

export interface SimulationState {
  requestId: string;
  conferenceCallId: number;
  ticker: string;
  eventTitle: string;
  transcriptUrl: string;
  status: string;
  lastProcessedTimestamp: number;
  lastSentenceTimestamp: number;
  prompt: string;
  summarizeMethod: string;
  intervalSec: number;
  dateCreated: string;
}

export interface SimulationResult {
  requestId: string;
  conferenceCallId: number;
  fromTimestamp: number;
  toTimestamp: number;
  items: RealtimeSummarySummarizedContent;
  dateCreated: string;
}

export interface RealtimeSummarySummarizedContent {
  summary?: RealtimeSummarySummarizedContentItem[];
  qna?: RealtimeSummarySummarizedQnaItem[];
  qnaSectionTime?: number;
  qnaSectionTimeReason?: string;
}

export interface RealtimeSummarySummarizedContentItem {
  s?: number;
  e?: number;
  c?: string;
}

export interface RealtimeSummarySummarizedQnaItem {
  s?: number;
  q?: string;
  a?: string;
}
