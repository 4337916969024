import styled from "@emotion/styled";
import { AppStoreUtil } from "../../util/appstore";
import { useIsMobile } from "../../util/mediaquery";
import { Colors } from "../Color";
import Typography from "../Typography";
import IconDownload from "../icon/ic_download.svg";
import IconLogo from "../icon/logo.svg";
import DownloadPopup from "./DownloadPopup";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LargeButton from "../buttons/LargeButton";

export enum Tab {
  Intro,
  EarningsCall,
}
interface Props {
  selectedTab: Tab;
  showBorder?: boolean;
}

const Container = styled.div<{ showBorder: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0px;
  height: 42px;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;

  border-bottom: ${({ showBorder }) => (showBorder ? `1px solid ${Colors.gray100}` : "none")};
  background-color: ${Colors.black};
  z-index: 500;
`;

const ButtonContainer = styled.div`
  margin: 0 2px 0 2px;
`;
const Button = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px 0px 3px 0px;
`;

const RoundWhitebutton = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 12px;
  margin-left: 14px;
  border-radius: 10px;
  background-color: ${Colors.gray10};

  > img {
    margin-right: 6px;
  }

  cursor: pointer;
`;

const Space = styled.div`
  flex: 1;
  height: 1px;
`;

const LogoImg = styled.img`
  width: 42px;
  height: 42px;

  cursor: pointer;
`;

const PopupLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.7);
`;

const PopupContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function TopHeader({ selectedTab, showBorder }: Props) {
  const navigate = useNavigate();
  const isMobile = AppStoreUtil.isMobile;
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleDownloadClick = () => {
    if (AppStoreUtil.isMobile) {
      window.open(AppStoreUtil.storeUrl, "_blank");
    } else {
      setIsPopupOpen(true);
    }
  };

  return (
    <Container showBorder={showBorder ?? false}>
      <LogoImg src={IconLogo} alt="logo" onClick={() => navigate("/")} />
      <Space />

      {!isMobile && (
        <>
          <ButtonContainer>
            <LargeButton
              text="서비스 소개"
              onClick={() => navigate("/")}
              border={false}
              color={selectedTab === Tab.Intro ? Colors.white : Colors.gray60}
            />
            {/* <Link to="/" style={{ textDecoration: "none" }}>
                  <Typography variant="8/semibold" text="서비스 소개" />
                </Link> */}
          </ButtonContainer>
          <ButtonContainer>
            <LargeButton
              text="어닝콜"
              onClick={() => navigate("/earnings-calls")}
              border={false}
              color={selectedTab === Tab.EarningsCall ? Colors.white : Colors.gray60}
            />
          </ButtonContainer>
        </>
      )}

      <RoundWhitebutton onClick={handleDownloadClick}>
        <img src={IconDownload} alt="download" />
        <Typography variant="7.5/semibold" text="앱 다운로드" color="black" />
      </RoundWhitebutton>

      {isPopupOpen && (
        <PopupLayer>
          <PopupContainer>
            <DownloadPopup onClose={() => setIsPopupOpen(false)} />
          </PopupContainer>
        </PopupLayer>
      )}
    </Container>
  );
}
