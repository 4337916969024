import { useEffect } from "react";
import { AppStoreUtil } from "../../util/appstore";
import styled from "@emotion/styled";
import Typography from "../../component/Typography";
import { Colors } from "../../component/Color";
import IcLogo from "../../component/icon/logo.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  gap: 10px;
`;

const AppIconContainer = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 26.33px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #262626;
  margin-bottom: 10px;

  > img {
    width: 92.75px;
    height: 92.75px;
  }
`;

export default function GoStore() {
  useEffect(() => {
    window.location.href = AppStoreUtil.storeUrl;
  }, []);

  return (
    <Container>
      <AppIconContainer>
        <img src={IcLogo} alt="logo" width={70} />
      </AppIconContainer>

      <Typography variant="9/semibold" color={Colors.gray20}>
        스톡나우
      </Typography>
      <Typography variant="6/regular" color={Colors.gray50}>
        앱 다운로드 페이지로 이동합니다.
      </Typography>
    </Container>
  );
}
