export const getPeriodText = (year: number, period: string) => {
  const yearText = year.toString().slice(-2);
  if (period === "Q1") {
    return `${yearText}년 1분기`;
  } else if (period === "Q2") {
    return `${yearText}년 2분기`;
  } else if (period === "Q3") {
    return `${yearText}년 3분기`;
  } else if (period === "Q4") {
    return `${yearText}년 4분기`;
  }
};
