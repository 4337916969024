import styled from "@emotion/styled";
import { EarningsDetailResponse, Ticker } from "../../api";
import { Colors } from "../../component/Color";
import icDefault from "../../component/icon/ic_default.svg";
import { Space } from "../../component/space";
import Typography from "../../component/Typography";

import SmallButton from "../../component/buttons/SmallButton";
import IconExppandable from "./images/item_expandable.svg";
import IconExpanded from "./images/item_expanded.svg";

import { useEffect, useState } from "react";
import EarningsItem from "./EarningsItem";
import { fetchEarningsByTicker } from "../../api/quries/earningEvent";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { easeOutQuart } from "../../component/transition";
import TickerIcon from "../../component/icon/TickerIcon";

interface Props {
  tickerInfo: Ticker;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Container = styled.div``;
const TickerContainer = styled.div`
  display: flex;
  align-items: center;
  height: 46px;
  padding: 20px;
  cursor: pointer;

  :hover {
    background-color: ${Colors.gray100};
  }
`;

const TickerImgContainer = styled.div`
  position: relative;
`;

const LiveAudioState = styled.div`
  position: absolute;
  top: -3px;
  left: -3px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  border: 1.5px solid ${Colors.red50};

  > div {
    position: absolute;
    bottom: -6px;
    left: 9.5px;
    width: 27px;
    height: 14px;
    border-radius: 40px;
    background-color: ${Colors.red50};

    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      width: 15.6px;
      height: 5.64px;
    }
  }
`;

const TickerImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #64646466;
`;

const TickerDesc = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const Tags = styled.div`
  flex: 1;
  padding: 0 10px;

  > span {
    background-color: #cecece;
    color: #000;
    font-weight: bold;
    padding: 2px 8px;
    margin: 0 4px 0 0;
    border-radius: 10px;
    font-size: 12px;
  }
`;

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
`;

const TimeWarpper = styled.div`
  display: flex;
  align-items: center;

  height: 33px;
  padding: 0 12px;
  border: 1px solid ${Colors.gray90};
  border-radius: 40px;
`;

const EmptyEarningsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const ExpandedContainer = styled.div<{ expanded: boolean; maxHeight: number }>`
  transition: max-height 0.4s ${easeOutQuart};
  max-height: ${({ expanded, maxHeight }) => (expanded ? `${maxHeight}px` : "0")};
  overflow: hidden;
`;

const ITEM_HEIGHT = 85;
export default function TickerItem({ tickerInfo, onClick, style }: Props) {
  const navigate = useNavigate();
  const { earningsId } = useParams();
  const [expanded, setExpanded] = useState(false);

  const { data: earnings, isFetching } = useQuery<EarningsDetailResponse[]>({
    queryKey: ["earnings", tickerInfo.ticker],
    queryFn: () => fetchEarningsByTicker(tickerInfo.ticker),
    enabled: expanded,
    staleTime: Infinity,
  });

  const maxHeight = isFetching ? 0 : earnings && earnings.length > 0 ? earnings.length * ITEM_HEIGHT : 82;
  console.log(earningsId);
  return (
    <Container>
      <TickerContainer onClick={() => setExpanded(!expanded)} style={style}>
        <TickerImgContainer>
          <TickerIcon iconUrl={tickerInfo.iconUrl} name={tickerInfo.ticker} />
          {/* {liveAudioState === "live" && (
            <LiveAudioState>
              <div>
                <img src={IconLiveBadge} alt="live-badge" />
              </div>
            </LiveAudioState>
          )} */}
        </TickerImgContainer>
        <TickerDesc>
          <Typography variant="8/bold" style={{ overflow: "hidden", whiteSpace: "nowrap", maxWidth: "130px" }}>
            {tickerInfo.ticker}
          </Typography>
          <Typography variant="6/regular" color={Colors.gray60}>
            {tickerInfo.displayName}
          </Typography>
        </TickerDesc>

        <Space />

        <Stats>
          {/* {transcriptUrl ? (
          <SmallButton icon={IconPlay} selectedIcon={IconPlaySelected} hoverIcon={IconPlayHover} selected={selected} />
        ) : (
          <TimeWarpper>
            <Typography variant="6/regular" text={DateTimeUtils.formatTimeSimple(liveEventAt!!)} color={Colors.gray40} />
          </TimeWarpper>
        )} */}
          <SmallButton
            circle
            icon={IconExppandable}
            selectedIcon={IconExpanded}
            style={{ transform: expanded ? "rotate(90deg)" : "rotate(0deg)", transition: `transform 0.4s ${easeOutQuart}` }}
          />
        </Stats>
      </TickerContainer>
      <ExpandedContainer maxHeight={maxHeight} expanded={expanded}>
        {!isFetching && earnings && earnings.length === 0 ? (
          <EmptyEarningsContainer>
            <Typography variant="5/regular" color={Colors.gray60}>
              등록된 어닝콜이 없습니다.
            </Typography>
          </EmptyEarningsContainer>
        ) : (
          earnings?.map((earning) => (
            <EarningsItem
              key={earning.id}
              earnings={earning}
              onClick={() => navigate(`/earnings-calls/${earning.ticker}/${earning.id}`)}
              selected={earningsId === earning.id.toString()}
            />
          ))
        )}
      </ExpandedContainer>
    </Container>
  );
}
