import { apiEndpoint } from "../config";
import { v4 as uuidv4 } from "uuid";

const DEVICE_ID_HEADER_NAME = "SN-DEVICE-ID";

function getDeviceId() {
  let deviceId = localStorage.getItem(DEVICE_ID_HEADER_NAME);
  if (!deviceId) {
    deviceId = uuidv4() as string;
    localStorage.setItem(DEVICE_ID_HEADER_NAME, deviceId);
  }
  return deviceId;
}

export async function callApi<T>(path: string, queryParams?: any, method = "GET"): Promise<T> {
  const headers = {
    "Content-Type": "application/json",
    [DEVICE_ID_HEADER_NAME]: getDeviceId(),
  };

  if (method === "GET") {
    const resp = await fetch(`${apiEndpoint}${path}${queryParams ? `?${new URLSearchParams(queryParams)}` : ""}`, { headers });
    const r = await resp.json();
    if (r.error) {
      if (r.error.includes("Invalid token")) {
        console.log("Unauthorized, logging out");
      }
      throw new Error(r.error);
    }
    return r;
  } else {
    const resp = await fetch(`${apiEndpoint}${path}`, {
      method: "POST",
      headers,
      body: queryParams ? JSON.stringify(queryParams) : undefined,
    });
    const r = await resp.json();
    if (r.error) {
      if (r.error.includes("Invalid token")) {
        console.log("Unauthorized, logging out");
      }
      throw new Error(r.error);
    }
    return r;
  }
}
