export const Colors = {
  white: "#FFFFFF",
  black: "#000000",
  gray50: "#8D8D8D",

  red50: "#F04438",

  green60: "#039855",

  //----------------
  gray10: "#F4F4F4",
  gray40: "#A8A8A8",
  gray20: "#EDEDEC",
  gray30: "#C8C8C8",
  gray60: "#6F6F6F",
  gray70: "#525252",
  gray80: "#393939",
  gray90: "#262626",
  gray100: "#161616",
};

export type Color = keyof typeof Colors;
