import styled from "@emotion/styled";
import { Colors } from "../Color";
import Typography from "../Typography";

const Container = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  padding: ${({ active }) => (active ? "5px 10px 5px 5px" : "5px 5px 5px 10px")};
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  cursor: pointer;

  background-color: ${Colors.gray90};
`;

const IconOnOff = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 42px;
  height: 42px;
  border-radius: 12px;

  background-color: ${({ active }) => (active ? Colors.white : Colors.gray50)};
`;

interface Props {
  active: boolean;
  onClick: () => void;
  text: string;
}

export default function ToggleButton({ active, onClick, text }: Props) {
  const getText = () => {
    return (
      <Typography variant="6/semibold" color={active ? Colors.gray30 : Colors.gray60}>
        {text}
      </Typography>
    );
  };
  return (
    <Container onClick={onClick} active={active}>
      {!active && getText()}
      <IconOnOff active={active}>
        <Typography variant="6/semibold" color={active ? Colors.black : Colors.gray30}>
          {active ? "ON" : "OFF"}
        </Typography>
      </IconOnOff>
      {active && getText()}
    </Container>
  );
}
