import { RealtimeSummarySummarizedContentItem, RealtimeSummarySummarizedQnaItem, SimulationResult } from "../../api/earningscall";

export interface ComposedResult {
  s: number;
  e: number;
  summary: RealtimeSummarySummarizedContentItem[];
  qna: RealtimeSummarySummarizedQnaItem[];
}

function getLastItemTime(item: SimulationResult) {
  return item.items.summary?.findLast((s) => s.s)?.s;
}
export function buildComposedResult(items: SimulationResult[]) {
  const sortedItems = items.sort((a, b) => a.toTimestamp - b.toTimestamp);

  // 1분당 결과별로 그 전의 내용들과 합치기
  return sortedItems.map((item, index) => {
    const targetItems = sortedItems.slice(0, index + 1);
    const result: ComposedResult = {
      s: item.fromTimestamp,
      e: item.toTimestamp,
      summary: [],
      qna: [],
    };
    targetItems.forEach((v) => {
      mergeSummary(result, v);
      mergeQna(result, v);
    });

    return result;
  });
}

function mergeSummary(merged: ComposedResult, item: SimulationResult) {
  const newSummaryList = item.items.summary || [];
  for (const newSummary of newSummaryList) {
    const exist: RealtimeSummarySummarizedContentItem | undefined = merged.summary?.find(
      (e) => e.s && newSummary.s && Math.abs(e.s - newSummary.s) < 10
    );
    if (exist) {
      // compare content
      if (exist.c!.length < newSummary.c!.length) {
        merged.summary = merged.summary?.filter((s) => JSON.stringify(s) !== JSON.stringify(exist));
        merged.summary.push(newSummary);
      }
    } else {
      merged.summary.push(newSummary);
    }
  }

  merged.summary = merged.summary?.sort((a, b) => a.s! - b.s!);
}

function mergeQna(merged: ComposedResult, item: SimulationResult) {
  const newSummaryList = item.items.qna || [];
  for (const newSummary of newSummaryList) {
    const exist: RealtimeSummarySummarizedQnaItem | undefined = merged.qna?.find((e) => e.s && newSummary.s && Math.abs(e.s - newSummary.s) < 10);
    if (exist) {
      // compare content
      if (exist.q!.length < newSummary.q!.length || exist.a!.length < newSummary.a!.length) {
        merged.qna = merged.qna?.filter((s) => JSON.stringify(s) !== JSON.stringify(exist));
        merged.qna.push(newSummary);
      }
    } else {
      merged.qna.push(newSummary);
    }
  }

  merged.qna = merged.qna?.sort((a, b) => a.s! - b.s!);
}
