import styled from "@emotion/styled";
import MuxAudio from "@mux/mux-audio-react";
import { MuxMediaProps } from "@mux/playback-core";
import { Colors } from "../../component/Color";
import { SyntheticEvent, useEffect, useRef, useState, useCallback } from "react";
import SmallButton from "../../component/buttons/SmallButton";
import Typography from "../../component/Typography";
import { Storage } from "../../util/storage";
import IconForward from "./images/forward.svg";
import IconForwardDisabled from "./images/forward_disabled.svg";
import IconPlay from "./images/play.svg";
import IconPlayDisabled from "./images/play_disabled.svg";
import IconPause from "./images/pause.svg";
import IconPauseDisabled from "./images/pause_disabled.svg";
import IconBackward from "./images/backward.svg";
import IconBackwardDisabled from "./images/backward_disabled.svg";
import IconVolume from "./images/volume.svg";
import IconVolumeDisabled from "./images/volume_disabled.svg";
import { easeOutQuart } from "../../component/transition";

const Container = styled.div`
  min-width: 500px;
  max-width: 700px;
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  margin: 0 100px;

  background: ${Colors.gray100};
  backdrop-filter: blur(60px);
  border-radius: 16px;
`;

const VolumeControl = styled.div<{ showVolume: boolean }>`
  position: absolute;
  bottom: 60px;
  right: 22px;
  width: 36px;
  height: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: flex-end;
  opacity: ${({ showVolume }) => (showVolume ? 1 : 0)};

  transition: opacity 0.4s ${easeOutQuart};
`;
//
const VolumeSlider = styled.div<{ value: number }>`
  width: 36px;
  height: 100px;
  background: ${Colors.gray100};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  > input {
    -webkit-appearance: none;
    width: 76px;
    transform: rotate(-90deg) translate(40px, 0px);
    background: ${Colors.gray80};
    border-radius: 30px;

    &:hover {
      opacity: 1;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: ${Colors.gray30};
      cursor: pointer;
      transform: translate(0px, -5.5px);

      box-shadow: 0px 4px 10px 0px #000000;
    }

    &::-moz-range-thumb {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: ${Colors.gray30};
      cursor: pointer;

      transform: translate(0px, -5.5px);
      box-shadow: 0px 4px 10px 0px #000000;
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 6px;
      cursor: pointer;
      background: linear-gradient(
        to right,
        ${Colors.gray30} 0%,
        ${Colors.gray30} ${(props) => props.value * 100}%,
        ${Colors.gray80} ${(props) => props.value * 100}%,
        ${Colors.gray80} 100%
      );
      border-radius: 30px;
    }

    &::-moz-range-track {
      width: 100%;
      height: 6px;
      cursor: pointer;
      background: ${Colors.red50};
      border-radius: 30px;
    }

    &::-moz-range-progress {
      background-color: ${Colors.red50};
      height: 5px;
      border-radius: 5px;
    }
  }
`;

const PlayerContainer = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;

  background: ${Colors.gray100};
  backdrop-filter: blur(60px);
  border-radius: 16px;
`;

const PlayerControls = styled.div`
  // align-self: stretch;
  height: 52px;

  display: flex;
  align-items: center;
  gap: 8px;
`;

const PlayerProgress = styled.div`
  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  gap: 8px;
`;

const PlayerProgressRange = styled.input<{ value: number }>`
  width: 100%;
  -webkit-appearance: none;
  background: ${Colors.gray80};
  border-radius: 30px;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${Colors.gray30};
    cursor: pointer;
    transform: translate(0px, -5.5px);

    box-shadow: 0px 4px 10px 0px #000000;
  }

  &::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${Colors.gray30};
    cursor: pointer;

    transform: translate(0px, -5.5px);
    box-shadow: 0px 4px 10px 0px #000000;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: linear-gradient(
      to right,
      ${Colors.red50} 0%,
      ${Colors.red50} ${(props) => props.value}%,
      ${Colors.gray80} ${(props) => props.value}%,
      ${Colors.gray80} 100%
    );
    border-radius: 30px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    background: ${Colors.red50};
    border-radius: 30px;
  }

  &::-moz-range-progress {
    background-color: ${Colors.red50};
    height: 5px;
    border-radius: 5px;
  }
`;

const TimeText = styled.div`
  width: 30px;
`;

const OtherControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Separator = styled.div`
  width: 1px;
  height: 24px;
  background: #232323;
  margin: 0 8px;
`;

interface CustomMuxAudioPlayerProps {
  // 추가적인 커스텀 props를 여기에 정의할 수 있습니다.
  src?: string;
  onProgress?: (time: number, duration: number, playState: "play" | "pause") => void;
  onUserInteraction?: (eventType: "quickSeek" | "progressSeek") => void;
  qnaTime?: number;
  requestSeekTime?: number;
  isLive?: boolean;
  wentLiveAt?: number;
}

export default function AudioPlayer({ src, requestSeekTime, onProgress, qnaTime, isLive, onUserInteraction, wentLiveAt }: CustomMuxAudioPlayerProps) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [currentTime, setCurrentTime] = useState<number>();
  const [progressCurrentTime, setProgressCurrentTime] = useState<number>();
  const [duration, setDuration] = useState<number>();
  const [progressChanging, setProgressChanging] = useState<boolean>(false);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [showVolume, setShowVolume] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(Storage.getVolume());

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleLoadedMetadata = () => {
      setDuration(audio.duration);
    };

    const timeUpdateHandler = (e: SyntheticEvent) => {
      const duration = isLive && wentLiveAt ? (Date.now() - wentLiveAt) / 1000.0 : audioRef.current?.duration;
      setCurrentTime(audioRef.current?.currentTime || 0);
      setDuration(duration || 0);
      onProgress?.(audioRef.current?.currentTime || 0, duration || 0, audioRef.current?.paused ? "pause" : "play");
    };

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("timeupdate", timeUpdateHandler as any);
    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("timeupdate", timeUpdateHandler as any);
    };
  }, [isLive, onProgress, src, wentLiveAt]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (!progressChanging) {
      setProgressCurrentTime(currentTime);
    }
  }, [currentTime, progressChanging]);

  useEffect(() => {
    var timeoutId: NodeJS.Timeout | undefined;
    if (showVolume) {
      timeoutId = setTimeout(() => {
        setShowVolume(false);
      }, 5000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [showVolume, volume]);

  useEffect(() => {
    if (requestSeekTime && audioRef.current) {
      console.log("set seek time: ", requestSeekTime);
      audioRef.current.currentTime = requestSeekTime;
      onProgress?.(audioRef.current?.currentTime || 0, duration || 0, audioRef.current?.paused ? "pause" : "play");
    }
  }, [requestSeekTime, audioRef, onProgress, duration]);

  const playOrPause = () => {
    if (audioRef.current) {
      audioRef.current.paused ? audioRef.current.play() : audioRef.current.pause();
    }
  };

  const seekTo = (time: number) => {
    if (audioRef.current) {
      audioRef.current.currentTime = time;
    }
    onUserInteraction?.("quickSeek");
  };

  const quickSeek = (forawrd: boolean) => {
    if (audioRef.current) {
      seekTo(audioRef.current.currentTime + 10 * (forawrd ? 1 : -1));
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const format = (value: number) => {
      return value.toString().padStart(2, "0");
    };
    return `${format(minutes)}:${format(seconds)}`;
  };

  const getProgressCurrentRange = () => {
    if (!duration) {
      return 0;
    }
    return ((progressCurrentTime || 0) / (duration || 0)) * 100;
  };

  const getTimeFromProgress = (progress: number) => {
    return ((duration || 0) * progress) / 100.0;
  };

  const onMouseUp = (e: SyntheticEvent) => {
    const value = getTimeFromProgress(Number((e.target as HTMLInputElement).value));
    setProgressChanging(false);
    setProgressCurrentTime(value);
    setCurrentTime(value);
    if (audioRef.current) {
      audioRef.current.currentTime = value;
    }
    onUserInteraction?.("progressSeek");
  };

  const onProgressChange = (e: SyntheticEvent) => {
    setProgressChanging(true);
    const value = getTimeFromProgress(Number((e.target as HTMLInputElement).value));
    setProgressCurrentTime(value);
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  const changeNextPlaybackRate = () => {
    const tables = [1.0, 1.25, 1.5, 2.0];
    const index = tables.indexOf(playbackRate);
    setPlaybackRate(tables[(index + 1) % tables.length]);
  };

  const handleVolumeChange = (e: SyntheticEvent) => {
    const value = Number((e.target as HTMLInputElement).value);
    setVolume(value);
    Storage.setVolume(value);
  };

  return (
    <Container>
      {/* <PlayerContainer> */}
      <PlayerControls>
        <SmallButton icon={IconBackward} disabledIcon={IconBackwardDisabled} onClick={() => quickSeek(false)} />
        {audioRef.current?.paused ? (
          <SmallButton icon={IconPlay} disabledIcon={IconPlayDisabled} onClick={playOrPause} />
        ) : (
          <SmallButton icon={IconPause} disabledIcon={IconPauseDisabled} onClick={playOrPause} />
        )}
        <SmallButton icon={IconForward} disabledIcon={IconForwardDisabled} onClick={() => quickSeek(true)} />
      </PlayerControls>
      <PlayerProgress>
        <TimeText>
          <Typography variant="2/regular" text={formatTime(progressCurrentTime || 0)} />
        </TimeText>
        <PlayerProgressRange onMouseUp={onMouseUp} onChange={onProgressChange} value={getProgressCurrentRange()} type="range" />
        <TimeText>
          {isLive ? (
            <Typography variant="2/regular" text={"LIVE"} color={Colors.red50} />
          ) : (
            <Typography variant="2/regular" text={formatTime(duration || 0)} />
          )}
        </TimeText>
      </PlayerProgress>
      <OtherControls>
        {qnaTime && qnaTime > 0 ? (
          <>
            <SmallButton text="Q&A" onClick={() => seekTo(qnaTime)} />
            <Separator />
          </>
        ) : undefined}
        {!isLive && (
          <>
            <SmallButton text={`${playbackRate}x`} onClick={() => changeNextPlaybackRate()} />
            <Separator />
          </>
        )}
        <SmallButton icon={IconVolume} disabledIcon={IconVolumeDisabled} onClick={() => setShowVolume(!showVolume)} />
      </OtherControls>
      {/* 추가 컴포넌트 */}
      <MuxAudio ref={audioRef} src={src} autoPlay={true} preload="auto" />

      <VolumeControl showVolume={showVolume}>
        <VolumeSlider value={volume}>
          <input type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange} />
        </VolumeSlider>
      </VolumeControl>
      {/* </PlayerContainer> */}
    </Container>
  );
}
