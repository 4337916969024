import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  getAllRealtimeEarningsCallSummarySimulationState,
  getRealtimeEarningsCallSummarySimulationResult,
  SimulationState,
} from "../../api/earningscall";
import { Colors } from "../../component/Color";
import { buildComposedResult, ComposedResult } from "./ResultBuilder";
import SimulationResultItem from "./SimulationResultItem";
import { Row } from "../row";
import Typography from "../Typography";
import { formatTime } from "../../util/time";

const Container = styled.div`
  user-select: text;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin: 0px 20px;
  background-color: ${Colors.black};
  border-radius: 10px;
  border: 1px solid ${Colors.gray50};
  height: 300px;
  overflow-y: scroll;
`;

const Headers = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${Colors.black};
  padding: 20px;
  z-index: 100;
  border-bottom: 1px solid ${Colors.gray50};
`;

const Label = styled.div`
  font-size: 14px;
`;

const Select = styled.select`
  width: 400px;
  height: 36px;
  border-radius: 8px;
`;

const Divider = styled.div`
  margin-top: 20px;
  width: 100%;
  height: 1px;
  background-color: ${Colors.gray50};
`;
const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Checkbox = styled.input`
  width: 30px;
  height: 30px;
`;

const ScriptContainer = styled.div`
  position: fixed;
  right: 0;
  padding: 5px;
  top: 100px;
  width: 500px;
  height: 100vh;
  background-color: ${Colors.black};
  border-left: 1px solid ${Colors.gray50};
  overflow-x: scroll;
`;

const TimeItem = styled.div`
  width: 30px;
  text-align: right;
`;

interface Script {
  s?: number;
  e?: number;
  tr?: string;
}

interface Props {
  conferenceCallId: number;
  curTime: number;
}

export default function LiveSummary({ conferenceCallId, curTime }: Props) {
  const [selectedSimulationRequestId, setSelectedSimulationRequestId] = useState("");
  const [composedResult, setComposedResult] = useState<ComposedResult[]>([]);

  const [selectedSimulationState, setSelectedSimulationState] = useState<SimulationState | undefined>(undefined);
  const { data: simulationStateList } = useQuery({
    queryKey: ["realtime-earnings-call-summary-simulation-state"],
    queryFn: () => getAllRealtimeEarningsCallSummarySimulationState(),
  });

  const { data: simulationResultList, refetch: refetchSimulationResultList } = useQuery({
    queryKey: ["realtime-earnings-call-summary-simulation-result", selectedSimulationRequestId],
    queryFn: () => getRealtimeEarningsCallSummarySimulationResult(selectedSimulationRequestId),
  });

  useEffect(() => {
    setSelectedSimulationState(simulationStateList?.find((state) => state.conferenceCallId === conferenceCallId));
  }, [simulationStateList, conferenceCallId]);

  useEffect(() => {
    if (selectedSimulationState) {
      setSelectedSimulationRequestId(selectedSimulationState.requestId);
    }
  }, [selectedSimulationState]);

  useEffect(() => {
    // compose
    if (simulationResultList) {
      const r = buildComposedResult(simulationResultList);
      setComposedResult(r);
    } else {
      setComposedResult([]);
    }
  }, [simulationResultList]);

  const curResult = composedResult.findLast((result) => {
    return curTime >= result.e;
  });

  return (
    composedResult.length > 0 && (
      <Container>
        {curResult && (
          <>
            {formatTime(curResult.s)} - {formatTime(curResult.e)} qna : {curResult.qna?.length > 0 && formatTime(curResult.qna[0].s!)}
          </>
        )}
        {curResult?.summary?.map((item) => (
          <Row style={{ gap: 20, alignItems: "flex-start" }}>
            <TimeItem>
              <Typography variant="2/regular" textAlign="right">
                {formatTime(Number(item.s))}
              </Typography>
            </TimeItem>
            <Typography variant="6/semibold">{item.c}</Typography>
          </Row>
        ))}
        {curResult?.qna?.map((item) => (
          <Row style={{ gap: 20, alignItems: "flex-start" }}>
            <TimeItem>
              <Typography variant="2/regular" textAlign="right">
                {formatTime(Number(item.s))}
              </Typography>
            </TimeItem>
            <div>
              <Typography variant="4/regular" component="div">
                Q: {item.q}
              </Typography>
              <Typography variant="4/regular" component="div">
                A: {item.a}
              </Typography>
            </div>
          </Row>
        ))}
        {formatTime(curTime)}
      </Container>
    )
  );
}
