import styled from "@emotion/styled";
import { SimulationResult } from "../../api/earningscall";
import { Row } from "../../component/row";
import Typography from "../../component/Typography";
import { formatTime } from "../../util/time";

interface Summary {
  s: string;
  c: string;
}

interface Props {
  result: SimulationResult;
  prevResult?: SimulationResult;
  showPreviousDiff: boolean;
  onSentenceClick: (startTimestamp: number) => void;
}

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TimeItem = styled.div`
  width: 30px;
  text-align: right;
`;

export default function SimulationResultItem({ result, prevResult, showPreviousDiff, onSentenceClick }: Props) {
  var summaryList = result.items.summary || [];
  var prevSummaryList = prevResult && prevResult.items.summary ? prevResult.items.summary : [];

  const addedSummaryList = prevSummaryList
    ? summaryList.filter((summary) => {
        return !prevSummaryList.find((prevSummary) => prevSummary.c === summary.c);
      })
    : [];

  const removedSummaryList = prevSummaryList.filter((summary) => {
    return !summaryList.find((s) => s.c === summary.c);
  });
  return (
    <div>
      <Typography variant="10/bold">
        {" "}
        {formatTime(result.fromTimestamp)} - {formatTime(result.toTimestamp)} (요약: {summaryList.length}개, qna: {result.items.qna?.length}, section
        time: {formatTime(result.items.qnaSectionTime || 0)}){" "}
        <Typography variant="2/regular" textAlign="right">
          {result.items.qnaSectionTimeReason}
        </Typography>
      </Typography>
      <List>
        {summaryList.map((summary) => (
          <Row style={{ gap: 20, alignItems: "flex-start" }} onClick={() => onSentenceClick(Number(summary.s))}>
            <TimeItem>
              <Typography variant="2/regular" textAlign="right">
                {formatTime(Number(summary.s))}
              </Typography>
            </TimeItem>
            <Typography variant="6/semibold">{summary.c}</Typography>
          </Row>
        ))}
        {showPreviousDiff &&
          addedSummaryList.map((summary) => (
            <Row style={{ gap: 20, alignItems: "flex-start" }}>
              <Typography variant="2/regular" textAlign="right">
                +{summary.c}
              </Typography>
            </Row>
          ))}
        {showPreviousDiff &&
          removedSummaryList.map((summary) => (
            <Row style={{ gap: 20, alignItems: "flex-start" }}>
              <Typography variant="2/regular" textAlign="right">
                -{summary.c}
              </Typography>
            </Row>
          ))}
      </List>

      <Typography variant="10/bold">QnA</Typography>
      <List>
        {result.items.qna &&
          result.items.qna.map((qna) => (
            <Row style={{ gap: 20, alignItems: "flex-start" }} onClick={() => onSentenceClick(Number(qna.s))}>
              <TimeItem>
                <Typography variant="2/regular" textAlign="right">
                  {formatTime(Number(qna.s))}
                </Typography>
              </TimeItem>
              <div>
                <Typography variant="6/regular" component="div">
                  Q: {qna.q}
                </Typography>
                <Typography variant="6/regular" component="div">
                  A: {qna.a}
                </Typography>
              </div>
            </Row>
          ))}
      </List>
    </div>
  );
}
